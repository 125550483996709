import React from "react"
import PageHero from "../components/pagehero"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import Styles from "../styles/components/CaseStudySection.module.scss"
import WorkCard from "../components/WorkCard"
import CTA from "../components/cta"
import MobileHeader from "../components/MobileHeader"

const CaseStudies = ({ data }) => {
  const { edges: caseStudy } = data.allCaseStudiesJson
  return (
    <div>
      <MobileHeader></MobileHeader>
      <PageHero
        heading={[
          "We have proofs of ",
          <span key="case-study">transformed</span>,
          " organizations",
        ]}
        description="Stories of how some of our work has helped drive innovation"
        imgSrc="/case-studies.jpg"
        pageTitle="Case Studies"
        secondaryButtonTitle="Free Consultation"
        secondaryButtonUrl="contact"
      ></PageHero>
      <div className={`${Styles.background} container-padding`}>
        <div className={Styles["section-line--title"]}>
          <h5>Clients and Projects</h5>
        </div>
        <div className={Styles["section-intro"]}>
          <h2>
            Our clients are simple amazing, they allow us do what we love
            creating impactful solutions everytime
          </h2>
          <div className={Styles["section-explainer"]}>
            <p>
              PhosMobile Partners with your business to deliver amazing digital
              experiences that drive innovation and fosters growth.
            </p>
          </div>
        </div>
        <div className={Styles["section-portfolio"]}>
          {caseStudy.map(({ node: post }) => (
            <WorkCard
              key={post.id}
              companyName={post.frontmatter.companyName}
              workTitle={post.frontmatter.workTitle}
              imageSrc={post.frontmatter.featureImage}
              slug={post.frontmatter.slug}
            ></WorkCard>
          ))}
          <div></div>
        </div>
      </div>
      <CTA />
      <Footer></Footer>
    </div>
  )
}

export const pageQuery = graphql`
  query caseStudyIndex {
    allCaseStudiesJson {
      edges {
        node {
          id
          challenge
          outcome
          frontmatter {
            slug
            workTitle
            featureImage
            companyName
          }
        }
      }
    }
  }
`

export default CaseStudies
